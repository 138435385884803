import { useCallback } from 'react';
import clsx from 'clsx';
import { Icon } from '@theme/components';
import { twMerge } from 'tailwind-merge';

export type Props = {
  title: string;
  icon?: string;
  index: number;
  setSelectedTab: (index: number) => void;
  isActive?: boolean;
  iconPosition?: string;
  className?: string;
  tabClassName?: string;
  activeTabClassName?: string;
  onClick?(): void;
};

export const Tab = (props: Props) => {
  const {
    title,
    setSelectedTab,
    index,
    isActive,
    icon,
    iconPosition,
    className,
    activeTabClassName,
    onClick
  } = props;

  const handleOnClick = useCallback(() => {
    setSelectedTab(index);
    onClick && onClick();
  }, [setSelectedTab, index, onClick]);

  if (!title) return;

  return (
    <button
      onClick={handleOnClick}
      className={twMerge(
        clsx(
          'flex cursor-pointer items-center justify-center break-words px-2 py-2 text-lg capitalize leading-[1.56]',
          isActive ? 'border-b-2 border-black text-primary' : 'text-gray-700',
          {
            'flex-col': iconPosition === 'top',
            'flex-col-reverse': iconPosition === 'bottom',
            'flex-row-reverse': iconPosition === 'end'
          }
        ),
        className,
        isActive && activeTabClassName
      )}
    >
      {icon && (
        <Icon
          name={icon}
          size={16}
          className={isActive ? 'fill-white' : 'fill-primary'}
        />
      )}
      {title}
    </button>
  );
};
