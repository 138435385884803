'use client';

import {
  useLocalization,
  useMediaQuery,
  useOnClickOutside
} from '@akinon/next/hooks';
import { Select } from './select';
import { Image } from '@akinon/next/components/image';
import { useRef, useState } from 'react';
import clsx from 'clsx';
import { Locale } from '@akinon/next/types';

interface LanguageSelectProps {
  className?: string;
}

type ExtendedLocaleType = Locale & {
  longLabel?: string;
};

export const LanguageSelect = (props: LanguageSelectProps) => {
  const {
    t,
    locale,
    locales,
    setLocale
  }: {
    t: (key: string) => string;
    locale: string;
    locales: ExtendedLocaleType[];
    setLocale: (locale: string) => void;
  } = useLocalization();

  const flags = {
    tr: '/flag-turkish.svg',
    en: '/flag-english.svg'
  };

  const [showPopover, setShowPopover] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const popoverRef = useRef(null);
  const popoverButtonRef = useRef(null);
  const currentLocale: ExtendedLocaleType = locales.find(
    (lang) => lang.value === locale
  );

  // Temporary fix for english, it will be replaced with locales array in the future
  const localesWithoutEN = locales.filter((locale) => locale.value !== 'en');

  useOnClickOutside(
    popoverRef,
    (e) => {
      if (popoverButtonRef.current.contains(e.target)) return;
      setShowPopover(false);
    },
    'mousedown'
  );

  const handleChange = async (e) => {
    const selectedLanguage = e.currentTarget.value;
    setLocale(selectedLanguage);
  };

  return (
    <div className="relative flex cursor-pointer select-none lg:static">
      <div
        className="flex"
        ref={popoverButtonRef}
        onClick={() => {
          if (isDesktop) {
            setShowPopover((prev) => !prev);
          }
        }}
      >
        <Image
          src={flags[locale]}
          alt="Flag"
          width={30}
          height={22}
          className="mr-2 block"
          unoptimized
        />

        <span className="hidden lg:block">{currentLocale?.longLabel}</span>
      </div>
      <div
        className={clsx('hidden lg:block', {
          'invisible opacity-0': !showPopover
        })}
      >
        <div
          ref={popoverRef}
          className="absolute right-0 top-[57px]  hidden w-[267px] flex-col gap-[7px] border border-lightBlue-500 bg-white p-5 lg:flex"
        >
          <Select
            labelClassName="text-sm font-medium text-gray-800 mb-1.5 block"
            onChange={() => {}}
            options={[{ value: 'Türkiye', label: 'Türkiye' }]}
            value={'Türkiye'}
            label={t('common.header.band.language_selector.country')}
            data-testid="language"
            optionClassName="text-primary"
            className="rounded-none border-lightBlue-600 px-4 [&_div]:text-base [&_div]:text-primary [&_i]:text-primary"
          />
          <Select
            labelClassName="text-sm font-medium text-gray-800 mb-1.5 block"
            onChange={(event) => {
              setShowPopover(false);
              handleChange(event);
            }}
            options={localesWithoutEN.map((lang) => ({
              value: lang.value,
              label: lang.longLabel
            }))}
            value={locale}
            optionClassName="text-primary"
            label={t('common.header.band.language_selector.language')}
            data-testid="language"
            className="rounded-none border-lightBlue-600 px-4 [&_div]:text-base  [&_div]:text-primary [&_i]:text-primary"
          />
        </div>
      </div>

      <Select
        wrapperClassName="lg:hidden"
        labelClassName="relative flex-row"
        selectWrapperClassName="!absolute w-full"
        onChange={handleChange}
        options={localesWithoutEN.map((lang) => ({
          value: lang.value,
          label: lang.label
        }))}
        value={locale}
        label={currentLocale?.longLabel}
        data-testid="language"
        borderless
        className={props.className}
      />
    </div>
  );
};
