import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { PaginationProps } from '@theme/components/types';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { Link } from '@theme/components';
import { Icon } from './icon';
import usePagination from '@akinon/next/hooks/use-pagination';
import { useLocalization } from '@akinon/next/hooks';
import { useRouter } from '@akinon/next/hooks';
import { useMediaQuery } from '@akinon/next/hooks';

export const Pagination = (props: PaginationProps) => {
  const { t } = useLocalization();
  const matches = useMediaQuery('(min-width: 1024px)');
  const router = useRouter();
  const {
    total,
    limit,
    currentPage,
    currentPageClassName,
    numberOfPages,
    containerClassName,
    prevClassName,
    pageClassName,
    nextClassName,
    threshold = 1,
    withOutText = false,
    render
  } = props;

  const pagination = usePagination(total, limit, currentPage, numberOfPages);
  const {
    total: paginationTotal,
    limit: paginationLimit,
    page,
    pageList,
    prev,
    next,
    setTotal,
    setLimit
  } = pagination;

  const [paginationItems, setPaginationItems] = useState([]);
  const showNext = currentPage * paginationLimit < total;

  const createListItems = useCallback(() => {
    setPaginationItems([]);
    const delta = matches ? 2 : 1;
    const startPage = Math.max(Number(page) - delta, 1);
    const endPage = Math.min(Number(page) + delta, numberOfPages);

    setPaginationItems((prev) => [
      ...prev,
      {
        page: pageList[0].page,
        url: pageList[0].url
      }
    ]);

    if (delta < startPage && startPage != 2) {
      setPaginationItems((prev) => [...prev, { page: '...', url: '#' }]);
    }

    for (let i = startPage; i <= endPage; i++) {
      if (i === 1) continue;

      setPaginationItems((prev) => [
        ...prev,
        {
          page: pageList[i - 1]?.page,
          url: pageList[i - 1]?.url
        }
      ]);
    }

    if (endPage < numberOfPages - threshold) {
      setPaginationItems((prev) => [...prev, { page: '...', url: '#' }]);
    }

    if (page < numberOfPages - delta) {
      setPaginationItems((prev) => [
        ...prev,
        {
          page: pageList[pageList.length - threshold].page,
          url: pageList[pageList.length - threshold].url
        }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfPages, page, pageList, threshold]);

  const handleClick = (e: MouseEvent<HTMLAnchorElement>, url: string) => {
    e.preventDefault();

    const newUrl = new URL(url, window.location.origin);
    const page = newUrl.searchParams.get('page');

    if (page === '1') {
      newUrl.searchParams.delete('page');
    }

    router.push(newUrl.pathname + newUrl.search, undefined);
  };

  useEffect(() => {
    createListItems();
  }, [createListItems, page]);

  useEffect(() => {
    if (total && total !== paginationTotal) {
      setTotal(total);
    }
  }, [total, paginationTotal, setTotal]);

  useEffect(() => {
    if (limit && limit !== paginationLimit) {
      setLimit(limit);
    }
  }, [limit, paginationLimit, setLimit]);

  if (render) {
    return <>{render(pagination)}</>;
  }

  return (
    <ul
      className={twMerge(
        'mb-10 mt-12 flex items-center justify-center lg:mb-[57px]',
        containerClassName
      )}
    >
      {prev && (
        <li className="mr-6">
          <Link
            onClick={(e) => handleClick(e, prev)}
            href={prev}
            className={twMerge('flex cursor-pointer text-sm', prevClassName)}
          >
            <span>
              <Icon name="arrow-left" size={20} />
            </span>
            {!withOutText && (
              <span className="ms-4 hidden lg:inline-block">
                {t('category.pagination.previous')}
              </span>
            )}
          </Link>
        </li>
      )}

      {paginationItems &&
        paginationItems?.map((item, i) => (
          <li
            key={i}
            className="mr-1.5 flex min-h-8 min-w-8 items-center justify-center"
          >
            {item?.url != '#' ? (
              <Link
                onClick={(e) => handleClick(e, item.url)}
                href={item.url}
                className={twMerge(
                  clsx(
                    'cursor-pointer px-2 text-xs',
                    { 'pointer-events-none': item.url === null },
                    Number(page) === Number(item?.page)
                      ? `font-semibold text-black-800 ${currentPageClassName}`
                      : 'text-gray-400'
                  ),
                  pageClassName
                )}
              >
                {item?.page}
              </Link>
            ) : (
              <span className="flex cursor-default items-center justify-center text-xs">
                {item?.page}
              </span>
            )}
          </li>
        ))}

      {showNext && (
        <li className="ml-[18px]">
          <Link
            onClick={(e) => handleClick(e, next)}
            href={next}
            className={twMerge('flex cursor-pointer text-xs', nextClassName)}
          >
            <span>
              <Icon name="arrow-right" size={20} />
            </span>
            {!withOutText && (
              <span className="ms-4 hidden lg:inline-block">
                {t('category.pagination.next')}
              </span>
            )}
          </Link>
        </li>
      )}
    </ul>
  );
};
