'use client';

import ReactPortal from './react-portal';

import { Icon } from './icon';
import { twMerge } from 'tailwind-merge';
import { useRef } from 'react';
import { DrawerModalProps } from './types';
import clsx from 'clsx';

export const DrawerModal = (props: DrawerModalProps) => {
  const {
    children,
    footer,
    portalId,
    open,
    setOpen,
    title = '',
    titleClass = '',
    wrapperClass,
    draggerClass,
    className
  } = props;
  const ref = useRef(null);

  const handleDrag = (e) => {
    const top = e.touches[0].clientY;
    const elTop = ref.current.getBoundingClientRect();

    if (elTop.top < top || window.innerHeight - elTop.height < top) {
      ref.current.style.top = `${top}px`;
    }
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    const top = e.changedTouches[0].clientY;

    if (top > 50) {
      setOpen(false);
    }
    ref.current.style.top = 'auto';
  };

  return (
    <ReactPortal wrapperId={portalId}>
      <div
        className={clsx(
          'fixed left-0 top-0 z-50 h-screen w-screen bg-black bg-opacity-80 backdrop-blur',
          open
            ? 'visible opacity-100'
            : 'pointer-events-none invisible opacity-0'
        )}
        onClick={() => setOpen(false)}
      />
      <div
        ref={ref}
        className={twMerge(
          clsx(
            open
              ? 'scroll-lock visible opacity-100'
              : 'pointer-events-none invisible translate-y-full opacity-0 md:translate-x-full md:translate-y-0',
            'fixed bottom-0 right-0 z-50 flex max-h-[80dvh] w-full flex-col bg-gray-25 transition-all duration-300 md:h-screen md:max-h-screen md:w-[27.5rem] md:rounded-t-none',
            className
          )
        )}
      >
        {open && (
          <>
            <div
              className={twMerge(
                'z-50 flex h-4 w-full items-center justify-center pb-4 pt-6 md:hidden',
                draggerClass
              )}
              onTouchMove={handleDrag}
              onTouchEnd={handleDragEnd}
            >
              <div className="h-1 w-[58px] rounded-lg bg-gray-700 bg-opacity-30"></div>
            </div>
            {title && (
              <header
                className={twMerge(
                  'flex items-center gap-2 border-b px-4 pb-4 pt-1 md:border-lightBlue md:bg-white md:px-8 md:pb-8 md:pt-10',
                  titleClass
                )}
              >
                <div className="text-lg font-medium text-primary md:text-2xl">
                  {title}
                </div>
                <Icon
                  name="close"
                  size={28}
                  className="ml-auto hover:cursor-pointer md:block"
                  onClick={() => setOpen(false)}
                />
              </header>
            )}
            <div className={twMerge('overflow-y-auto p-4', wrapperClass)}>
              {children}
            </div>
            {footer && (
              <footer className="mt-auto grid grid-cols-2 gap-5 px-4 py-4 md:bg-white md:px-6">
                {footer}
              </footer>
            )}
          </>
        )}
      </div>
    </ReactPortal>
  );
};
