import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { Icon } from './icon';
import { FieldError } from 'react-hook-form';
import { SelectItem, SelectProps } from './types';
import { Image } from '@akinon/next/components/image';
import { Radio } from './radio';

interface CustomSelectItem extends SelectItem {
  icon?: string;
}

interface CustomSelectProps extends SelectProps {
  options: CustomSelectItem[];
  optionClassName?: string;
  optionListClassName?: string;
  borderless?: boolean;
  position?: string;
  icon?: string;
  iconSize?: number;
  error?: FieldError | undefined;
  required?: boolean;
  search?: boolean;
  showOnlyIcon?: boolean;
  chevronClass?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  appearance?: 'default' | 'dark';
}

export const CustomSelect = forwardRef<HTMLSelectElement, CustomSelectProps>(
  (props, ref) => {
    const {
      className,
      options,
      borderless = false,
      icon,
      iconSize,
      error,
      label,
      labelClassName,
      value,
      onChange,
      required = false,
      position,
      chevronClass,
      optionClassName,
      optionListClassName,
      search = false,
      showOnlyIcon = false,
      wrapperClassName,
      appearance = 'default',
      ...rest
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [tempValue, setTempValue] = useState<CustomSelectItem>(options[0]);
    const internalRef = useRef<HTMLSelectElement | null>(null);
    const wrapperRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');

    const filteredOptions = search
      ? options.filter((option) =>
          option.label
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      : options;

    const handleOptionClick = (option) => {
      setTempValue(option);
      internalRef.current.value = option.value;
      const event = new Event('change', { bubbles: true });
      internalRef.current.dispatchEvent(event);

      setIsOpen(false);
    };

    const handleClick = (e) => {
      e.preventDefault();
      setIsOpen(!isOpen);
    };

    const selected = useMemo(
      () =>
        options.find((option) => option.value === tempValue?.value) ??
        options[0],
      [options, tempValue]
    );

    useEffect(() => {
      handleOptionClick(selected);
      function handleClickOutside(e) {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wrapperRef]);

    useEffect(() => {
      if (value) {
        const option = options.find(
          (option) => String(option.value) === String(value)
        );
        setTempValue(option);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useImperativeHandle(ref, () => internalRef.current);

    const variants = {
      default: 'bg-white text-primary border-gray-505',
      dark: 'bg-primary-200 text-white border-primary-200'
    };

    return (
      <div className={wrapperClassName}>
        <div className="flex items-center gap-2">
          {icon && <Icon name={icon} size={iconSize ?? 20} />}
          <div
            className={twMerge(
              clsx('relative flex w-full flex-col', {
                'flex-col-reverse': position === 'top'
              })
            )}
            ref={wrapperRef}
          >
            <div>
              {label && (
                <span
                  className={twMerge(
                    clsx(
                      'mb-2 block text-primary',
                      appearance == 'dark' ? 'text-gray-400' : 'text-primary',
                      labelClassName
                    )
                  )}
                >
                  {label}
                  {required && <span className="text-secondary">*</span>}
                </span>
              )}
              <button
                onClick={handleClick}
                className={twMerge(
                  clsx(
                    'text-body-base flex w-full items-center justify-between gap-4 rounded-sm border !py-[10px] px-4 font-thin',
                    '!border-lightBlue-600 !text-gray-850 !text-opacity-100',
                    { 'border-none': borderless },
                    variants[appearance],
                    className
                  )
                )}
              >
                <div className="flex	w-10/12 items-center gap-1">
                  {selected?.icon && (
                    <Image
                      className="flex-shrink-0"
                      src={`/${selected?.icon}.svg`}
                      alt={selected?.value.toString()}
                      width={16}
                      height={16}
                      aspectRatio={1}
                    />
                  )}
                  {!showOnlyIcon && (
                    <div className="overflow-hidden text-ellipsis whitespace-nowrap text-sm">
                      {selected?.label}
                    </div>
                  )}
                </div>
                <Icon
                  name="chevron-down"
                  size={12}
                  className={twMerge(
                    clsx(
                      'pointer-events-none transition-all',
                      { 'rotate-180': isOpen },
                      chevronClass
                    )
                  )}
                />
              </button>
            </div>

            {isOpen && (
              <div className="relative">
                <ul
                  className={twMerge(
                    clsx(
                      'absolute right-0 z-10 max-h-40 w-full min-w-[80px] overflow-y-scroll border bg-white shadow-sm',
                      {
                        'bottom-1 top-auto': position === 'top'
                      },
                      [
                        appearance == 'dark'
                          ? 'border-primary'
                          : 'border-gray-300'
                      ],
                      { '-bottom-5': label && position === 'top' },
                      optionListClassName
                    )
                  )}
                >
                  {search && (
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="z-[11] w-full border border-[#ccc] p-[10px]"
                    />
                  )}
                  {filteredOptions.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => handleOptionClick(option)}
                      className={twMerge(
                        clsx(
                          'flex w-full cursor-pointer items-center gap-1 px-4 py-3 text-primary transition-all hover:bg-gray-100',
                          {
                            'bg-primary-200 text-white hover:bg-primary':
                              appearance == 'dark'
                          },
                          optionClassName
                        )
                      )}
                    >
                      <Radio
                        onChange={() => handleOptionClick(option)}
                        value={option.value}
                        checked={option.value === selected?.value}
                      >
                        {option.label}
                      </Radio>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <select
            {...rest}
            ref={internalRef}
            className="hidden"
            onChange={onChange}
            value={selected?.value}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {error && (
          <span className="mt-1 text-sm text-error">{error.message}</span>
        )}
      </div>
    );
  }
);

CustomSelect.displayName = 'Select';
