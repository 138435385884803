import { forwardRef, useState, useEffect } from 'react';
import { CheckboxProps } from './types';
import { twMerge } from 'tailwind-merge';
import { useMediaQuery } from '@akinon/next/hooks';
import { Icon } from './icon';
import clsx from 'clsx';

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    children,
    error,
    inputClassName,
    inputWidth = 16,
    inputHeight = 16,
    labelClassName,
    errorClassName,
    wrapperClassName,
    ...rest
  } = props;

  const matches = useMediaQuery('(min-width: 64rem)');

  const [isDesktop, setIsDesktop] = useState(false);
  const [isChecked, setIsChecked] = useState(props.checked || false);

  useEffect(()=>{
    setIsChecked(props.checked)
  },[props.checked])

  useEffect(() => {
    setIsDesktop(matches);
  }, [matches]);

  const style = {
    width: `${inputWidth}px`,
    height: `${inputHeight}px`
  };

  if (Array.isArray(inputWidth)) {
    if (!isDesktop) {
      style['width'] = `${inputWidth[0]}px`;
    } else {
      style['width'] = `${inputWidth[1]}px`;
    }
  } else {
    style['width'] = `${inputWidth}px`;
  }

  if (Array.isArray(inputHeight)) {
    if (!isDesktop) {
      style['height'] = `${inputHeight[0]}px`;
    } else {
      style['height'] = `${inputHeight[1]}px`;
    }
  } else {
    style['height'] = `${inputHeight}px`;
  }

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);

    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <label
      className={twMerge(
        'flex cursor-pointer flex-col text-sm',
        props.className
      )}
    >
      <div className={clsx('flex items-center', wrapperClassName)}>
        <span className="flex-center relative">
          <input
            style={style}
            type="checkbox"
            {...rest}
            ref={ref}
            className={twMerge(
              'appearance-none border border-gray-450 transition-all duration-150 checked:border-primary checked:bg-gray-25',
              inputClassName
            )}
            onChange={handleCheckboxChange}
          />
          {(isChecked || props.checked) && (
            <Icon
              name="check"
              className="absolute text-[0.625rem] text-primary"
            />
          )}
        </span>
        {children && (
          <span className={twMerge('ml-2', labelClassName)}>{children}</span>
        )}
      </div>
      {error && (
        <span className={twMerge('mt-1 text-sm text-error', errorClassName)}>
          {error.message}
        </span>
      )}
    </label>
  );
});

Checkbox.displayName = 'Checkbox';

export { Checkbox };
