'use client';

import { ReactNode, useEffect, useRef, useState } from 'react';
import { Icon } from './icon';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

type AccordionProps = {
  isCollapse?: boolean;
  title?: string;
  subTitle?: string;
  icons?: string[];
  iconSize?: number;
  iconColor?: string;
  children?: ReactNode;
  className?: string;
  titleClassName?: string;
  dataTestId?: string;
  iconHeight?: number;
  iconClassName?: string;
  haveRoute?: boolean;
  routePath?: string;
  isProductDetail?: boolean;
  nearIcon?: boolean;
  childrenClassName?: string;
  activeClassName?: string;
  facetKey?: string;
};

export const Accordion = ({
  isCollapse = false,
  title,
  subTitle,
  icons = ['chevron-up', 'chevron-down'],
  iconSize = 16,
  iconColor = 'fill-[#000000]',
  children,
  className,
  titleClassName,
  dataTestId,
  iconHeight,
  iconClassName,
  haveRoute = false,
  routePath,
  isProductDetail = false,
  nearIcon = false,
  childrenClassName,
  facetKey,
  activeClassName
}: AccordionProps) => {
  const [collapse, setCollapse] = useState(isCollapse);
  const ref = useRef(null);

  useEffect(() => {
    if (isCollapse && ref.current) {
      ref.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
    setCollapse(isCollapse);
  }, [isCollapse]);

  return (
    <div
      ref={ref}
      className={twMerge(
        'flex flex-col justify-center border-b border-t border-lightBlue py-8',
        className,
        collapse ? activeClassName : ''
      )}
    >
      <div
        className={clsx(
          'accordion-wrapper flex cursor-pointer items-center px-6',
          isProductDetail ? 'gap-3' : 'justify-between',
          facetKey === 'category_ids' && 'hidden',
          facetKey === 'in_stock' && 'hidden',
          nearIcon ? '!justify-start !gap-4' : ''
        )}
        onClick={() => {
          if (haveRoute) {
            window.location.href = routePath;
          }
          setCollapse(!collapse);
        }}
        data-testid={dataTestId}
      >
        {icons && isProductDetail && (
          <Icon
            name={collapse ? icons[0] : icons[1]}
            size={iconSize}
            className={`fill-[${iconColor}]} h-[${iconHeight}px] ${iconClassName}`}
          />
        )}

        <div className="flex flex-col">
          {title && (
            <span className={twMerge('text-lg', titleClassName)}>{title}</span>
          )}
          {subTitle && <h4 className="text-xs text-gray-700">{subTitle}</h4>}
        </div>
        {icons && !isProductDetail && (
          <Icon
            name={collapse ? icons[0] : icons[1]}
            size={iconSize}
            className={`fill-[${iconColor}]} h-[${iconHeight}px] ${iconClassName}`}
          />
        )}
      </div>
      {collapse && (
        <div
          className={twMerge(
            `${nearIcon ? 'mt-0' : 'mt-6'} ml-[22px] px-6 text-sm lg:ml-0`,
            childrenClassName
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};
