'use client';

import { ButtonProps } from '@theme/components/types';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export const Button = (props: ButtonProps) => {
  return (
    <button
      {...props}
      className={twMerge(
        clsx(
          [
            'disabled:pointer-events-none disabled:bg-gray-500 disabled:text-gray-700 disabled:border-gray-500',
            'px-5',
            'h-10',
            'lg:h-12',
            'bg-primary',
            'text-primary-foreground',
            'border',
            'border-primary',
            'transition-all',
            "hover:bg-gray-850 hover:border-gray-850",
            'focus:border focus:border-primary',
            'active:bg-gray-100 active:border-gray-100 active:text-primary',
          ],
          props.appearance === 'outlined' && [
            'disabled:border-gray-600 disabled:text-gray-600',
            'bg-transparent',
            'text-primary',
            'hover:bg-gray-100',
            'focus:bg-lightBlue',
            'active:bg-lightBlue-700 active:text-white'
          ],
          props.appearance === 'outlined-white' && [
            'disabled:border-gray-600 disabled:text-gray-600',
            'bg-transparent',
            'text-primary',
            'hover:border-gray-850 hover:text-gray-850 hover:bg-transparent',
            'focus:border-gray-600 focus:border-[2px]',
            'active:border-lightBlue active:border-[1px] active:text-primary'
          ],
          props.appearance === 'ghost-underline' && [
            'disabled:bg-transparent disabled:text-gray-600 disabled:decoration-gray-600 disabled:border-transparent',
            'bg-transparent',
            'underline underline-offset-4',
            'border-transparent',
            'text-primary',
            'hover:bg-transparent hover:text-gray-850 hover:decoration-gray-850 hover:border-transparent',
            'focus:bg-transparent focus:text-gray focus:decoration-gray focus:border-transparent',
            'active:bg-transparent active:text-lightBlue-900 active:decoration-text-lightBlue-900 active:border-transparent'
          ],
          props.appearance === 'ghost' && [
            'bg-transparent',
            'border-transparent',
            'text-primary',
            'hover:border-transparent hover:bg-transparent hover:text-primary',
            'focus:border-transparent focus:bg-transparent focus:text-primary',
            'active:border-transparent active:bg-transparent active:text-primary'
          ]
        ),
        props.className
      )}
    >
      {props.children}
    </button>
  );
};
