import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { Icon } from './icon';
import { SelectItem, SelectProps } from './types';
import { Radio } from './radio';
import { useMediaQuery } from '@akinon/next/hooks';

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const isMobile = useMediaQuery('(max-width: 575px)');
  const {
    className,
    options,
    borderless = false,
    error,
    label,
    value,
    customLabel,
    defaultSelected,
    wrapperClassName,
    required = false,
    onChange,
    position = isMobile ? 'top' : 'bottom',
    chevronClass,
    optionClassName,
    labelClassName,
    selectWrapperClassName,
    iconSize = 12,
    hideDefaultOption = false,
    useRadioInOptions = false,
    hasScroll = false,
    interactiveKeyBoard = false,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [tempValue, setTempValue] = useState<SelectItem>(options[0]);
  const internalRef = useRef<HTMLSelectElement | null>(null);
  const wrapperRef = useRef(null);

  const handleOptionClick = (option) => {
    setTempValue(option);
    internalRef.current.value = option.value;
    const event = new Event('change', { bubbles: true });
    internalRef.current.dispatchEvent(event);

    setIsOpen(false);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const initialVal = options?.find((option) => option.value == defaultSelected);

  const selected = useMemo(() => {
    if (!tempValue?.value && initialVal) {
      return initialVal;
    }

    return (
      options.find((option) => option.value === tempValue?.value) ??
      initialVal ??
      options[0]
    );
  }, [options, tempValue, initialVal]);

  useEffect(() => {
    function handleClickOutside(e) {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (value) {
      const option = options.find(
        (option) => String(option.value) === String(value)
      );
      setTempValue(option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useImperativeHandle(ref, () => internalRef.current);

  const optionsRef = useRef([]);

  useEffect(() => {
    if (!interactiveKeyBoard || !isOpen) return;

    let typedKeys = '';
    let searchTimeout;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (optionsRef.current?.length === 0) return;

      typedKeys += e.key.toLocaleLowerCase('tr-TR');

      clearTimeout(searchTimeout);

      searchTimeout = setTimeout(() => {
        const findedElement = optionsRef.current.find((item) => {
          if (!item?.textContent) return false;
          const itemLabel = item.textContent?.toLocaleLowerCase('tr-TR');
          return itemLabel.startsWith(typedKeys);
        });

        if (findedElement) {
          findedElement.scrollIntoView({
            block: 'start',
            behavior: 'smooth'
          });
        }

        typedKeys = '';
      }, 1000);
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      clearTimeout(searchTimeout);
    };
  }, [isOpen, interactiveKeyBoard]);

  return (
    <div className={wrapperClassName}>
      <div className="flex items-center gap-2">
        <div
          className={twMerge(
            clsx('relative flex w-full flex-col', {
              'flex-col-reverse': position === 'top'
            })
          )}
          ref={wrapperRef}
        >
          <div>
            {label && (
              <span
                className={clsx('mb-1 cursor-pointer', labelClassName)}
                onClick={handleClick}
              >
                {label} {required && <span className="text-secondary">*</span>}
              </span>
            )}
            <button
              onClick={handleClick}
              className={twMerge(
                clsx(
                  'flex h-10 w-full items-center justify-between gap-4 rounded-sm border border-gray px-5 text-base',
                  { 'border-none': borderless },
                  className
                )
              )}
            >
              <div className="flex w-10/12 items-center gap-1">
                <div className="overflow-hidden text-ellipsis whitespace-nowrap text-sm text-gray-850 lg:text-xs">
                  {customLabel ?? selected?.label}
                </div>
              </div>
              <Icon
                name="chevron-down"
                size={iconSize}
                className={twMerge(
                  clsx(
                    'pointer-events-none transition-all',
                    { 'rotate-180': isOpen },
                    chevronClass
                  )
                )}
              />
            </button>
          </div>

          {isOpen && (
            <div className="relative">
              <ul
                className={twMerge(
                  clsx(
                    'absolute right-0 top-1 z-10 max-h-40 w-full min-w-[80px] overflow-y-scroll overscroll-contain rounded-sm border border-gray-300 bg-white shadow-md',
                    {
                      'bottom-1 top-auto': position === 'top'
                    },
                    { '-bottom-5': label && position === 'top' },
                    { 'invisible-overflow': !hasScroll },
                    selectWrapperClassName
                  )
                )}
              >
                {options.map((option, index) => (
                  <li
                    key={index}
                    ref={(el) => (optionsRef.current[index] = el)}
                    hidden={hideDefaultOption && index === 0}
                    className={twMerge(
                      clsx(
                        'flex w-full cursor-pointer items-center gap-1 px-4 py-2 transition-all hover:bg-gray-100',
                        { 'first:hidden': hideDefaultOption && index === 0 },
                        optionClassName
                      )
                    )}
                    onClick={() => handleOptionClick(option)}
                  >
                    {useRadioInOptions ? (
                      <>
                        <Radio
                          className="w-full cursor-pointer"
                          value={option.value}
                          inputClassName={rest?.radioClassName}
                          checked={option.value === selected?.value}
                        >
                          {option.label}
                        </Radio>
                      </>
                    ) : (
                      option.label
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <select
          {...rest}
          ref={internalRef}
          className="hidden"
          onChange={onChange}
          value={selected?.value || defaultSelected}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {error && (
        <span className="mt-1 text-sm text-error">{error.message}</span>
      )}
    </div>
  );
});

Select.displayName = 'Select';

export { Select };
