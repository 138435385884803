import { Metadata } from '@akinon/next/types';

const pwaTags: Metadata = {
  manifest: '/manifest.json',
  themeColor: '#FFFFFF',
  formatDetection: {
    telephone: false
  },
  appleWebApp: {
    capable: true,
    title: 'Paşabahçe Mağazaları',
    statusBarStyle: 'default'
  },
  other: {
    ['mobile-web-app-capable']: 'yes',
    ['application-name']: 'Paşabahçe Mağazaları'
  }
};

export default pwaTags;
