'use client';

import clsx from 'clsx';
import ReactPortal from './react-portal';
import { twMerge } from 'tailwind-merge';
import { BottomSheetProps } from './types';
import { Icon } from './icon';

export const BottomSheet = (props: BottomSheetProps) => {
  const {
    children,
    open,
    setOpen,
    portalId,
    className,
    title,
    icon,
    draggerClass,
    titleIconSize,
    headerClassName,
    closeButtonSize
  } = props;

  return (
    <ReactPortal wrapperId={portalId}>
      <div
        className={twMerge(
          clsx(
            'invisible fixed left-0 top-0 z-50 flex h-screen w-screen bg-black bg-opacity-30 opacity-0 transition-all duration-500 md:hidden',
            open ? 'scroll-lock visible opacity-100' : 'invisible opacity-0'
          )
        )}
        role="button"
        onClick={() => setOpen(false)}
      />

      <div
        className={twMerge(
          clsx(
            'invisible fixed bottom-0 left-0 z-50 flex w-full translate-y-72 transform flex-col rounded-t-sm bg-white opacity-0 transition-all duration-500 md:hidden',
            { 'visible translate-y-0 opacity-100': open },
            className
          )
        )}
      >
        <div
          className={twMerge(
            'z-50 flex h-4 w-full items-center justify-center pb-4 pt-6 md:hidden',
            draggerClass
          )}
        >
          <div className="h-1 w-[58px] rounded-lg bg-gray-700 bg-opacity-30"></div>
        </div>
        {title && (
          <header
            className={clsx('flex items-center px-4 pb-4', headerClassName)}
          >
            {icon && (
              <Icon
                name={icon}
                size={titleIconSize || 24}
                className="mr-2 text-secondary hover:cursor-pointer"
              />
            )}
            <div className="text-lg font-medium text-primary">{title}</div>
            <Icon
              name="close"
              size={closeButtonSize || 20}
              className="ml-auto block hover:cursor-pointer"
              onClick={() => setOpen(false)}
            />
          </header>
        )}
        {children}
      </div>
    </ReactPortal>
  );
};
