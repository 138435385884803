import { forwardRef, useState, useEffect } from 'react';
import { RadioProps } from './types';
import { twMerge } from 'tailwind-merge';
import { useMediaQuery } from '@akinon/next/hooks';

const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const {
    children,
    error,
    inputClassName,
    inputWidth = 20,
    inputHeight = 20,
    spanClassName,
    ...rest
  } = props;

  const matches = useMediaQuery('(min-width: 1024px)');

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsDesktop(matches);
  }, [matches]);

  const style = {
    width: `${inputWidth}px`,
    height: `${inputHeight}px`
  };

  if (Array.isArray(inputWidth)) {
    if (!isDesktop) {
      style['width'] = `${inputWidth[0]}px`;
    } else {
      style['width'] = `${inputWidth[1]}px`;
    }
  } else {
    style['width'] = `${inputWidth}px`;
  }

  if (Array.isArray(inputHeight)) {
    if (!isDesktop) {
      style['height'] = `${inputHeight[0]}px`;
    } else {
      style['height'] = `${inputHeight[1]}px`;
    }
  } else {
    style['height'] = `${inputHeight}px`;
  }

  return (
    <label
      className={twMerge(
        'flex cursor-pointer  items-center text-base',
        props.className
      )}
    >
      <input
        style={style}
        type="radio"
        {...rest}
        ref={ref}
        className={twMerge(
          'appearance-none rounded-full border border-gray-450 transition-all duration-150',
          'checked:border-[6px] checked:border-white checked:bg-primary checked:ring-1 checked:ring-primary',
          inputClassName
        )}
      />
      {children && (
        <span className={twMerge('ml-2', spanClassName)}>{children}</span>
      )}

      {error && (
        <span className="mt-1 text-sm text-error">{error.message}</span>
      )}
    </label>
  );
});

Radio.displayName = 'Radio';

export { Radio };
